import React from "react";
import "./css/index.css";
import { Input } from "antd";
import {
	ellipse,
	ellipse2,
	profile,
	right_arrow,
	search,
	send,
} from "../../../assets";

const ChatItem = ({ image, name, description }) => (
	<div className="d-flex align-items-start mx-3 mb-4">
		<img src={image} style={{ width: 50 }} alt="Profile" />
		<div className="ms-2">
			<span>{name}</span>
			{description && <p className="light-grey m-0">{description}</p>}
		</div>
	</div>
);

const MessageItem = ({ image, name, description, rightAlign }) => (
	<div
		className={`${rightAlign ? "bg-blue" : "bg-white"} radius2 p-3 d-flex my-4`}
		style={{
			alignItems: "start",
			flexDirection: rightAlign ? "row-reverse" : "row",
		}}
	>
		<img src={image} alt="Profile" />
		<div className={rightAlign ? "me-4" : "ms-4"}>
			<h5 className={`${rightAlign ? "d-flex justify-content-end" : ""}`}>
				{name}
			</h5>
			<span
				className={`${rightAlign ? "white" : "light-grey"} d-flex`}
				style={{ textAlign: rightAlign ? "end" : "start" }}
			>
				{description}
			</span>
		</div>
	</div>
);

const AdminSupport = () => {
	const queueChats = new Array(7).fill({
		image: profile,
		name: "John Smith",
	});
	const previousChats = new Array(7).fill({
		image: profile,
		name: "John Smith",
		description: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
	});
	const chats = new Array(4).fill({
		image: profile,
		name: "John Smith",
		description: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
	});

	return (
		<div className="bg-white2">
			<div
				className="container radius1 bg-white p-4"
				style={{ marginTop: 65 }}
			>
				<div className="bg-grey2 radius1 p-3">
					<div className="d-flex center">
						<img src={ellipse} alt="Chats in Queue" />
						<h5 className="m-0 ms-2">Chats in Queue</h5>
					</div>
					<div className="mt-3 d-flex flex-wrap">
						{queueChats.map((chat, i) => (
							<div key={i} className="mx-3 mt-2">
								<img
									src={chat.image}
									style={{ width: 40 }}
									alt="Profile"
								/>
								<span className="ms-2">{chat.name}</span>
							</div>
						))}
					</div>
				</div>
				<div className="mt-3">
					<div className="row">
						<div className="col-lg-6">
							<div className="bg-grey2 radius1 p-3">
								<h5 className="m-0">Previous chats</h5>
								<div className="d-flex searchStyle bg-white py-1 my-3">
									<Input
										placeholder="Search here..."
										className="whiteSearchStyle bg-white"
									/>
									<img
										className="cursor me-3"
										style={{ width: 15 }}
										src={search}
										alt="Search Icon"
									/>
								</div>
							</div>
							<div className="bg-grey4 pt-4">
								{previousChats.map((chat, i) => (
									<ChatItem key={i} {...chat} />
								))}
							</div>
						</div>
						<div className="col-lg-6">
							<div className="bg-grey2 p-3 radius1">
								<div className="d-flex justify-content-between center">
									<div className="d-flex center">
										<img
											src={right_arrow}
											alt="Right Arrow"
										/>
										<h5 className="m-0 ms-2">John Smith</h5>
									</div>
									<div className="center liveChatHeadView">
										<div className="d-flex center liveChat">
											<img
												src={ellipse2}
												alt="Live Chat"
											/>
											<h5 className="m-0 ms-2">
												Live Chat
											</h5>
										</div>
										<span className="ms-4 red-border radius1 red2 px-2">
											End Chat
										</span>
									</div>
								</div>
							</div>
							<div className="bg-grey4 pt-4">
								{chats.map((chat, i) => (
									<MessageItem
										key={i}
										{...chat}
										rightAlign={i % 2 === 1}
									/>
								))}
							</div>
							<div className="d-flex justify-content-between align-items-center mt-3">
								<Input.TextArea
									className="messageInput me-3"
									rows={2}
									placeholder="Type your message here..."
								/>
								<img
									src={send}
									className="cursor"
									alt="Send Message"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminSupport;
