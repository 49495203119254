import React from "react";
import { folder, home, paper, pen, settings, user, message } from "./assets";
import Dashboard from "./views/private/Dashboard";
import UserInformation from "./views/private/UserInformation";
import DataSection from "./views/private/DataSection";
import AdminSupport from "./views/private/AdminSupport";
import Payment from "./views/private/Payment";
import PreviousNotes from "./views/private/PreviousNotes";
import Settings from "./views/private/Settings";
import UserProfile from "./views/private/UserProfile";
import TopNFTs from "./views/private/topNFTs";
import Login from "./views/public/Login";
import NotFound from "./views/public/NotFound";
import { LogoutOutlined } from "@ant-design/icons";
import { AiOutlineSend } from "react-icons/ai";
import { Outlet } from "react-router-dom";

const routes = [
	{
		name: "Dashboard",
		layout: "private",
		path: "/",
		icon: home,
		component: <Dashboard />,
		visible: true,
		isNav: true,
		key: 6,
	},
	{
		name: "Not Found",
		layout: "public",
		path: "/404",
		icon: "",
		component: <NotFound />,
		visible: true,
		isNav: false,
		key: 404,
	},
	{
		name: "User Information",
		layout: "private",
		path: "/user-information",
		icon: user,
		component: <Outlet />,
		visible: true,
		key: 7,
		isNav: true,
		nested: true,
		nestedRoutes: [
			{
				name: "User Profile",
				layout: "private",
				path: "user-profile/:id",
				icon: user,
				component: <UserProfile />,
				visible: true,
				isNav: false,

				key: 13,
			},
			{
				name: "User Information",
				layout: "private",
				path: "",
				icon: user,
				index: true,
				component: <UserInformation />,
				visible: true,
				isNav: true,
				key: 66,
			},
		],
	},
	{
		name: "Data Section",
		layout: "private",
		path: "/data-section",
		icon: folder,
		component: <DataSection />,
		visible: true,
		isNav: true,
		key: 8,
	},
	{
		name: "Admin Support",
		layout: "private",
		path: "/admin-support",
		icon: message,
		component: <AdminSupport />,
		visible: true,
		isNav: true,
		key: 9,
	},
	{
		name: "Payment",
		layout: "private",
		path: "/payment",
		icon: paper,
		component: <Payment />,
		visible: true,
		isNav: true,
		key: 10,
	},
	{
		name: "Previous Notes",
		layout: "private",
		path: "/previous-notes",
		icon: pen,
		component: <PreviousNotes />,
		visible: true,
		// isDisabled: true,
		isNav: true,
		key: 11,
	},
	{
		name: "Settings",
		layout: "private",
		path: "/settings",
		icon: settings,
		component: <Settings />,
		visible: true,
		isNav: true,

		key: 12,
	},

	{
		name: "NFTs",
		layout: "private",
		path: "/top-nfts",
		icon: <AiOutlineSend />,
		component: <TopNFTs />,
		visible: true,
		key: 100,
		isNav: true,
		// isDisabled: true,
	},
	{
		name: "Login",
		layout: "public",
		path: "/login",
		icon: "plus",
		component: <Login />,
		visible: true,
		key: 1090,
		isNav: true,
		// isDisabled: true,
	},
	{
		name: "Logout",
		layout: "private",
		path: "/logout",
		icon: <LogoutOutlined />,
		component: null,
		visible: true,
		key: 10980,
		isNav: true,
		onClick: "handleLogout",
	},
];

export default routes;
