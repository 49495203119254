import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import routes from "../../route";
import { useProtectedRoutes } from "../../hooks/useProtectedRoutes";
import { NavbarComponent } from "../../components";
import { trimAfterFirstSlash } from "../../utills/reusableFunctions";

const PrivateLayout = () => {
	const { Protected } = useProtectedRoutes();
	const location = useLocation();

	const nonNavbarRoutes = ["/login"];
	const validRoutes = routes
		?.filter((r) => r?.path !== "/404")
		.map((route) => route.path);

	const lightNavbarRoutes = [
		"/user-information",
		"",
		"/data-section",
		"/previous-notes",
		"/settings",
	];

	const renderNestedRoutes = (nestedRoutes) =>
		nestedRoutes?.map((nestedRoute) => (
			<Route
				key={nestedRoute?.key || nestedRoute?.path}
				index={nestedRoute?.index}
				element={<Protected>{nestedRoute?.component}</Protected>}
				path={nestedRoute?.path}
			/>
		));

	const shouldDisplayNavbar = () =>
		!nonNavbarRoutes.includes(trimAfterFirstSlash(location.pathname)) &&
		validRoutes.includes(trimAfterFirstSlash(location.pathname));

	return (
		<>
			{shouldDisplayNavbar() && (
				<NavbarComponent
					lightNav={lightNavbarRoutes.includes(location.pathname)}
				/>
			)}

			<Routes>
				{routes
					?.filter((r) => r?.layout === "private" && r?.component)
					?.map((route) => (
						<Route
							key={route?.key || route?.path}
							path={route.path}
							element={
								route.nested ? (
									route.component
								) : (
									<Protected>{route.component}</Protected>
								)
							}
						>
							{route.nested &&
								renderNestedRoutes(route.nestedRoutes)}
						</Route>
					))}
			</Routes>
		</>
	);
};

export default PrivateLayout;
