import React from "react";
import "./css/index.css";
import { Menu, Tooltip } from "antd";
import { logo2, toggle_menu } from "../../assets";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "../../route";
import { removeStorage } from "../../utills/localStorage";
import ToastMessage from "../toastMessage";

const MobMenuComponent = ({ className, showMenu, setShowMenu }) => {
	let navigate = useNavigate();
	const { routeAccess } = useSelector(
		(state) => state.adminDetails.adminDetails,
	);

	const handleLogout = () => {
		removeStorage(process.env.REACT_APP_ADMIN_TOKEN);
		removeStorage(process.env.REACT_APP_ADMIN_EMAIL);
		navigate("/login");
		ToastMessage("Logout Successfully", "", "success");
	};

	const getItems = () => {
		const result = [];
		const belongsToMap = {};
		const allowedRoutes = [...(routeAccess || []), "/logout"];
		routes.forEach((route) => {
			if (route.isNav && allowedRoutes?.includes(route?.path)) {
				const iconElement =
					typeof route?.icon === "string" ? (
						<img src={route.icon} alt={route.name} />
					) : (
						route?.icon
					);
				let labelElement = route.name;
				if (route?.layout === "public") return;
				// Check if route is not visible
				if (route.isDisabled) {
					labelElement = (
						<Tooltip
							title={`${route.name} (Coming Soon)`}
							placement="right"
						>
							<div style={{ cursor: "not-allowed" }}>
								{route.name}
							</div>
						</Tooltip>
					);
				}

				let routeItem = {
					icon: iconElement,
					key: route?.key,
					label: route.isDisabled ? (
						<Tooltip
							title={`${route.name} (Coming Soon)`}
							placement="right"
						>
							<div style={{ cursor: "not-allowed" }}>
								{route.name}
							</div>
						</Tooltip>
					) : (
						labelElement
					),
				};

				if (route.belongsTo) {
					if (!belongsToMap[route.belongsTo]) {
						belongsToMap[route.belongsTo] = {
							label: route.belongsTo,
							children: [],
							icon: (
								<img
									src={route.belongsToIcon}
									alt={route.belongsTo}
								/>
							),
							key: routes?.length + Math.random(),
						};
						result.push(belongsToMap[route.belongsTo]);
					}
					belongsToMap[route.belongsTo].children.push(routeItem);
				} else {
					result.push(routeItem);
				}
			}
		});

		return result;
	};
	const handleClick = (key) => {
		const route = routes?.find((route) => route?.key === parseInt(key));
		if (route?.isDisabled) return;
		if (route?.onClick) return handleLogout();
		return navigate(route?.path);
	};
	const getSelectedKey = () => {
		return JSON.stringify(
			routes?.find((route) => route?.path === location?.pathname)?.key,
		);
	};
	return (
		<>
			{showMenu && (
				<div
					className={`${className} bg-dark-blue2`}
					style={{
						width: 256,
						zIndex: 1,
						overflow: "auto",
						position: "fixed",
						left: 0,
					}}
				>
					<div style={{ width: 260, height: 55 }} className="mt-2">
						<div className="d-flex justify-content-between center ps-2 pe-3">
							<div className="d-flex center">
								<img src={logo2} width="60px" />
								<h5 className="red ms-3 semi-bold m-0">BITS</h5>
							</div>
							<img
								src={toggle_menu}
								onClick={() => setShowMenu(false)}
								className="cursor"
							/>
						</div>
					</div>
					<div className="grey-border2 mt-2 mb-4 mx-3"></div>
					<Menu
						className="manuStyle bg-dark-blue2"
						defaultSelectedKeys={[getSelectedKey()]}
						mode="inline"
						theme="dark"
						inlineCollapsed={false}
						items={getItems()}
						onSelect={(item) => {
							handleClick(item?.key);
						}}
					/>
				</div>
			)}
		</>
	);
};

export default MobMenuComponent;
