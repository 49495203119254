const INITIAL_STATE = {};

const AuthReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default:
			return state;
	}
};

export default AuthReducer;
