import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getStorage } from "../utills/localStorage";
import Loader from "../components/loaders/loading";
import routes from "../route";
import { trimAfterFirstSlash } from "../utills/reusableFunctions";

export const useProtectedRoutes = () => {
	const [loading, setLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(null);
	const navigate = useNavigate();
	const validRoutes = routes?.map((route) => route?.path);
	let token = getStorage("adminToken");

	useEffect(() => {
		if (token) {
			setIsAuthenticated(true);
			setLoading(false);
			return;
		} else {
			setIsAuthenticated(false);
			setLoading(false);
		}
	}, [token]);

	useEffect(() => {
		if (!validRoutes) return;
		if (!validRoutes?.includes(trimAfterFirstSlash(location?.pathname)))
			return navigate("/404");
	}, [validRoutes]);

	const Protected = ({ redirectPath = "/login", children }) => {
		return loading ? (
			<Loader content="authenticating..." />
		) : isAuthenticated ? (
			children
		) : (
			<Navigate to={redirectPath} />
		);
	};

	const Public = ({ redirectPath = "/", children }) => {
		return loading ? (
			<Loader content="authenticating..." />
		) : !isAuthenticated ? (
			children
		) : (
			<Navigate to={redirectPath} />
		);
	};

	return { Protected, Public };
};
