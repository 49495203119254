import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import App from "./App";

const GRAPHQL_URI = process.env.REACT_APP_BACKEND_BASE_URL
	? `${process.env.REACT_APP_BACKEND_BASE_URL}/graphql`
	: "http://localhost:4000/graphql"; // Default URL

const client = new ApolloClient({
	uri: GRAPHQL_URI,
	cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<App />
		</ApolloProvider>
	</React.StrictMode>,
);

reportWebVitals();
