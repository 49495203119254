import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import routes from "../../route";
import { useProtectedRoutes } from "../../hooks/useProtectedRoutes";

const PublicLayout = () => {
	const { Public } = useProtectedRoutes();
	const location = useLocation();
	const publicRoutes = ["/login"];

	const renderRouteComponent = (component, path) =>
		publicRoutes.includes(path) ? <Public>{component}</Public> : component;

	return (
		<Routes>
			{routes
				?.filter((r) => r?.layout === "public")
				?.map((route) => (
					<Route
						key={route?.key || route?.path} // Ensure a unique key
						path={route.path}
						element={renderRouteComponent(
							route?.component,
							location.pathname,
						)}
					>
						{route.nested &&
							route?.nestedRoutes?.map((nestedRoute) => (
								<Route
									key={nestedRoute?.key || nestedRoute?.path}
									index={nestedRoute?.index}
									element={renderRouteComponent(
										nestedRoute?.component,
										location.pathname,
									)}
									path={nestedRoute?.path}
								/>
							))}
					</Route>
				))}
		</Routes>
	);
};

export default PublicLayout;
