import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import "../src/theme/theme.css";
import store from "./store/index";
import { loadContractIns } from "./store/actions";
import Layout from "./layout";
import { removeStorage } from "../src/utills/localStorage";

function App() {
	useEffect(() => {
		const removeWalletItems = () => {
			removeStorage("walletconnect");
			removeStorage("WALLETCONNECT_DEEPLINK_CHOICE");
		};
		store.dispatch(loadContractIns());
		removeWalletItems();
	}, []);

	return (
		<Provider store={store}>
			<Layout />
			<ToastContainer transition={Slide} />
		</Provider>
	);
}

export default App;
