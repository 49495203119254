import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	bar_chart,
	message2,
	refresh,
	// trending_down,
	// trending_up,
	// trending_up2,
	user2,
	users,
} from "../../../assets";
import { StatisticsCard } from "../../../components";
import "./css/index.css";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import {
	GET_ALL_NOTIFICATIONS,
	GET_NEW_REGISTRATION,
	GET_ALL_CONTACTS,
	GET_ALL_VISITS,
} from "../../../gql/queries";
import { useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
// import polygonMarketContractAbi from "../../abis/polygonMarketContractAbi.json";
import {
	// likes,
	// nfts,
	// topNfts,
	visits,
	users as registerdUsers,
} from "./sampleData";

// import { ethers } from "ethers";
import { grabEvents } from "../../../utills/grabEvents";
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
	},
	elements: {
		line: {
			tension: 0.5,
		},
	},
	scales: {
		y: {
			grid: {
				color: "rgba(255, 255, 255, 0.2)",
			},
		},
	},
};

const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const getLabels = (type) => {
	console.log({ type });
	const years = [
		...new Set(
			visits.map((item) => new Date(item.timestamp).getFullYear()),
		),
	];
	return years;
};

const labels = getLabels("Max");

export const data = {
	labels,
	datasets: [
		{
			label: "Visits",
			data: labels.map(
				(date) =>
					visits.filter(
						(item) =>
							new Date(item.timestamp).getFullYear() === date,
					).length,
			),
			borderColor: "#24318D",
			backgroundColor: "#24318D",
			shadowColor: "#24318D",
		},
		{
			label: "Watch time",
			data: labels.map(() =>
				faker.datatype.number({
					min: 0,
					max: Math.max(
						...labels.map(
							(date) =>
								visits.filter(
									(item) =>
										new Date(
											item.timestamp,
										).getFullYear() === date,
								).length,
						),
					),
				}),
			),
			borderColor: "#fff",
			backgroundColor: "#fff",
			shadowColor: "#fff",
		},
	],
};

function completeYearRange(years) {
	const minYear = Math.min(...years);
	const maxYear = Math.max(...years);
	const completeRange = Array.from(
		{ length: maxYear - minYear + 1 },
		(_, i) => minYear + i,
	);
	return completeRange;
}

function getCurrentWeekDates() {
	const currentDate = new Date();
	const currentDay = currentDate.getDay();
	const firstDayOfWeek = new Date(currentDate);
	firstDayOfWeek.setDate(currentDate.getDate() - currentDay);

	const formattedWeekDates = Array.from({ length: 7 }, (_, i) => {
		const date = new Date(firstDayOfWeek);
		date.setDate(firstDayOfWeek.getDate() + i);
		return `${String(date.getDate()).padStart(2, "0")} ${monthNames[date.getMonth()]}`;
	});

	return formattedWeekDates;
}

function getCurrentMonthDatesFormatted() {
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();

	const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
	const formattedMonthDates = Array.from(
		{ length: daysInMonth },
		(_, day) => {
			const date = new Date(currentYear, currentMonth, day + 1);
			return `${String(date.getDate()).padStart(2, "0")} ${monthNames[date.getMonth()]}`;
		},
	);

	return formattedMonthDates;
}

const Dashboard = () => {
	const {
		// loading: contactsLoading,
		// error: contactsError,
		data: contactData,
		refetch: dataRefetch,
	} = useQuery(GET_ALL_CONTACTS);

	const {
		// loading: visitsLoading,
		// error: visitsError,
		data: visitData,
		refetch: visitdataRefetch,
	} = useQuery(GET_ALL_VISITS);

	// const [likesData, setLikesData] = useState(likes);
	// const [nftsData, setNftsData] = useState(nfts);
	// const [topNftsData, setTopNftsData] = useState(topNfts);
	const [visitsData, setVisitsData] = useState([]);
	const [registeredUsersData, setRegisteredUsersData] = useState([]);
	const [nftsSoldData, setNftsSoldData] = useState([]);
	const [newRegisterations, setNewRegisterations] = useState(0);

	// States to show n graph Box
	const [visitDataGraphValue, setvisitDataGraphValue] = useState(0);
	const [registerdUserGraphValue, setregisterdUserGraphValue] = useState(0);
	const [loginUserGraphValue, setloginUserGraphValue] = useState(0);
	const [nftSoldGraphValue, setnftSoldGraphValue] = useState(0);
	const [newRegGraphValue, setnewRegGraphValue] = useState(0);

	console.log({ newRegisterations, newRegGraphValue });

	const getUniqueYears = (data, dateField) => {
		return completeYearRange([
			...new Set(
				data.map((item) => new Date(item[dateField]).getFullYear()),
			),
		]);
	};

	const getLabels = (type, graphToShowType) => {
		if (type === "Max") {
			switch (graphToShowType) {
				case "Total Registered users":
					return getUniqueYears(registeredUsersData, "createdAt");

				case "Active users":
					return getUniqueYears(
						registeredUsersData.filter((item) => item.is_login),
						"createdAt",
					);

				case "Unique Visitors":
				case "Total Visits":
					return getUniqueYears(visitsData, "timestamp");

				case "NFTs Sold":
					return getUniqueYears(nftsSoldData, "createdAt");

				case "New Registrations":
					return getUniqueYears(newRegisterations, "createdAt");

				default:
					return [];
			}
		} else if (type === "Week") {
			return getCurrentWeekDates();
		} else if (type === "Month") {
			return getCurrentMonthDatesFormatted();
		} else if (type === "Year") {
			const monthNames = [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December",
			];
			return monthNames;
		}
	};

	// let topUsersDatas = [
	// 	{
	// 		name: "John",
	// 		status: "Speedy walkover",
	// 		value: "8.2k",
	// 	},
	// 	{
	// 		name: "Katty",
	// 		status: "Speedy walkover",
	// 		value: "8.2k",
	// 	},
	// 	{
	// 		name: "Hannah",
	// 		status: "Speedy walkover",
	// 		value: "8.2k",
	// 	},
	// 	{
	// 		name: "John",
	// 		status: "Speedy walkover",
	// 		value: "8.2k",
	// 	},
	// 	{
	// 		name: "Katty",
	// 		status: "Speedy walkover",
	// 		value: "8.2k",
	// 	},
	// 	{
	// 		name: "Hannah",
	// 		status: "Speedy walkover",
	// 		value: "8.2k",
	// 	},
	// 	{
	// 		name: "Katty",
	// 		status: "Speedy walkover",
	// 		value: "8.2k",
	// 	},
	// 	{
	// 		name: "Hannah",
	// 		status: "Speedy walkover",
	// 		value: "8.2k",
	// 	},
	// ];
	// let notifications = [
	// 	{
	// 		name: "Hannah",
	// 		status: "Updated  profile",
	// 	},
	// 	{
	// 		name: "Hannah",
	// 		status: "Registered on BITS",
	// 	},
	// 	{
	// 		name: "Hannah",
	// 		status: "Updated  profile",
	// 	},
	// 	{
	// 		name: "Hannah",
	// 		status: "Registered on BITS",
	// 	},
	// 	{
	// 		name: "Hannah",
	// 		status: "Updated  profile",
	// 	},
	// 	{
	// 		name: "Hannah",
	// 		status: "Registered on BITS",
	// 	},
	// ];

	const [dataArr, setDataArr] = useState(data);

	// const {
	// 	loading,
	// 	error,
	// 	data: totalRegistered,
	// 	refetch,
	// } = useQuery(GET_USERS_COUNT);
	// console.log("totalRegistered", totalRegistered?.GetAllUsersCount);
	const navigate = useNavigate();

	const { data: allNotifications } = useQuery(GET_ALL_NOTIFICATIONS);
	// console.log("allNotifications", allNotifications);

	const { data: newRegistration } = useQuery(GET_NEW_REGISTRATION);
	// console.log("newRegistration", newRegistration);

	const { topUsersData } = useSelector(
		(state) => state.topUsers.topUsersData,
	);
	// const contracts = useSelector((state) => state.contracts);
	const { transactionData } = useSelector(
		(state) => state.totalTrans.transactionData,
	);
	const [contractEvents, setContractEvents] = useState([]);

	useEffect(() => {
		async function init() {
			grabEvents(setContractEvents);
		}
		init();
	}, []);

	useEffect(() => {
		dataRefetch();
	}, []);

	useEffect(() => {
		visitdataRefetch();
	}, []);

	useEffect(() => {
		if (contactData?.GetAllUsers) {
			let temp = contactData?.GetAllUsers;
			setRegisteredUsersData(temp);
			setregisterdUserGraphValue(temp.length);
			setloginUserGraphValue(
				temp?.filter((item) => item.is_login).length,
			);
			setNewRegisterations(temp?.slice(0, 5));
		}
	}, [contactData?.GetAllUsers]);

	useEffect(() => {
		if (visitData?.GetAllVisits) {
			let temp = visitData?.GetAllVisits;
			setVisitsData(temp);
			setvisitDataGraphValue(temp.length);
		}
	}, [visitData?.GetAllVisits]);

	useEffect(() => {
		if (transactionData?.soldnft) {
			let temp = transactionData?.soldnft;
			setNftsSoldData(temp);
			setnftSoldGraphValue(temp.length);
		}
	}, [transactionData?.soldnft]);

	// console.log("contractEvents", contractEvents);

	const [ghraphToShow, setGhraphToShow] = useState({
		type: "Total Visits",
		bg: "#2F49D1",
	});
	const [graphDataType, setGraphDataType] = useState("Max");

	const setGraph = (type, min, value, color) => {
		const graphDataType = min === "min" ? "Min" : "Max";

		const getFilteredData = (data, dateFilter) => {
			return data.filter((item) => {
				const itemDate = new Date(item.timestamp || item.createdAt);
				return dateFilter.getFullYear() === itemDate.getFullYear();
			});
		};

		const getData = (date, data, isLoginCondition = false) => {
			console.log({ isLoginCondition });
			switch (graphDataType) {
				case "Max":
					return getFilteredData(data, date).length;
				case "Year":
					return data.filter((item) => {
						const itemDate = new Date(
							item.timestamp || item.createdAt,
						);
						return (
							itemDate.getFullYear() ===
								new Date().getFullYear() &&
							itemDate.getMonth() === date.getMonth()
						);
					}).length;
				case "Month":
					return getFilteredData(data, date).length;
				case "Week":
					return getFilteredData(data, date).length;
				default:
					throw new Error("Invalid graphDataType");
			}
		};

		const labels = getLabels(graphDataType, type);

		const data = labels.map((date) => {
			switch (type) {
				case "Total Visits":
				case "Unique Visitors":
				case "NFTs Sold":
				case "New Registrations":
					return getData(new Date(date), visitsData);
				case "Total Registered users":
				case "Active users":
					return getData(new Date(date), registeredUsersData, true);
				default:
					throw new Error("Invalid type");
			}
		});

		const dataToSet = {
			labels,
			datasets: [
				{
					label: type,
					data,
					borderColor: color,
					backgroundColor: color,
				},
			],
		};

		setDataArr(dataToSet);
		setGhraphToShow({ type, bg: color });
	};

	useEffect(() => {
		setGraph("Total Visits", 23, 24, "#2F49D1");
	}, [graphDataType]);

	useEffect(() => {
		const filterByDate = (data, dateKey, specificDate) =>
			data.filter((item) => {
				const itemDate = new Date(item[dateKey]);
				return (
					itemDate.getFullYear() === specificDate.getFullYear() &&
					itemDate.getMonth() === specificDate.getMonth() &&
					itemDate.getDate() === specificDate.getDate()
				);
			});

		const filterByMonth = (data, dateKey, targetYear, monthIndex) =>
			data.filter((item) => {
				const itemDate = new Date(item[dateKey]);
				return (
					itemDate.getFullYear() === targetYear &&
					itemDate.getMonth() === monthIndex
				);
			});

		const calculateMonthlyData = (data, dateKey, currentYear) =>
			labels.map(
				(_, monthIndex) =>
					filterByMonth(data, dateKey, currentYear, monthIndex)
						.length,
			);

		const calculateDateSpecificData = (data, dateKey) =>
			labels.map(
				(date) => filterByDate(data, dateKey, new Date(date)).length,
			);

		const currentYear = new Date().getFullYear();

		if (graphDataType === "Max") {
			setvisitDataGraphValue(visitsData.length);
			setregisterdUserGraphValue(registeredUsersData.length);
			setloginUserGraphValue(
				registeredUsersData.filter((item) => item.is_login).length,
			);
			setnftSoldGraphValue(nftsSoldData.length);
			setnewRegGraphValue(newRegisterations.length);
		} else if (graphDataType === "Year") {
			setvisitDataGraphValue(
				calculateMonthlyData(visits, "timestamp", currentYear).length,
			);
			setregisterdUserGraphValue(
				calculateMonthlyData(
					registeredUsersData,
					"createdAt",
					currentYear,
				).length,
			);
			setloginUserGraphValue(
				calculateMonthlyData(
					registeredUsersData,
					"createdAt",
					currentYear,
				).length,
			);
			setnftSoldGraphValue(
				calculateMonthlyData(nftsSoldData, "createdAt", currentYear)
					.length,
			);
			setnewRegGraphValue(
				calculateMonthlyData(
					newRegisterations,
					"createdAt",
					currentYear,
				).length,
			);
		} else if (graphDataType === "Month" || graphDataType === "Week") {
			setvisitDataGraphValue(
				calculateDateSpecificData(visitsData, "timestamp").length,
			);
			setregisterdUserGraphValue(
				calculateDateSpecificData(registeredUsersData, "createdAt")
					.length,
			);
			setloginUserGraphValue(
				calculateDateSpecificData(registeredUsersData, "createdAt")
					.length,
			);
			setnftSoldGraphValue(
				calculateDateSpecificData(nftsSoldData, "createdAt").length,
			);
			setnewRegGraphValue(
				calculateDateSpecificData(newRegisterations, "createdAt")
					.length,
			);
		}
	}, [graphDataType]);

	return (
		<div className="bg-dark-blue">
			<div className="container py-4 dashboardContainer">
				<h5 className="white">Dashboard</h5>
				<div className="row card-data">
					<StatisticsCard
						icon={bar_chart}
						// count={totalRegistered?.GetAllUsersCount?.totalVisits}
						count={visitDataGraphValue}
						status={"Total Visits"}
						onClick={() =>
							setGraph(
								"Total Visits",
								100,
								// totalRegistered?.GetAllUsersCount?.totalVisits,
								visitsData.length,
								"#2F49D1",
							)
						}
						ghraphToShow={ghraphToShow}
						// trendingIcon={trending_up}
						// trendingPer={"0.24"}
						// duration={"Last month"}
						// perColor={"green"}
						// iconBgColor={"#232645"}
					/>
					<StatisticsCard
						icon={user2}
						// count={totalRegistered?.GetAllUsersCount?.uniqueVisitors}
						count={visitDataGraphValue}
						status={"Unique Visitors"}
						onClick={() =>
							setGraph(
								"Unique Visitors",
								100,
								// totalRegistered?.GetAllUsersCount?.uniqueVisitors,
								visitsData.length,
								"#FFB748",
							)
						}
						ghraphToShow={ghraphToShow}
						// trendingIcon={trending_up}
						// trendingPer={"0.24"}
						// duration={"Last month"}
						// perColor={"green"}
						// iconBgColor={"#383137"}
					/>
					<StatisticsCard
						icon={message2}
						// count={totalRegistered?.GetAllUsersCount?.registered}
						count={registerdUserGraphValue}
						status={"Total Registered users"}
						// trendingIcon={trending_down}
						// trendingPer={"20"}
						// duration={"Last month"}
						// perColor={"red2"}
						// iconBgColor={"#282347"}
						onClick={() =>
							setGraph(
								"Total Registered users",
								100,
								// totalRegistered?.GetAllUsersCount?.registered,
								registeredUsersData.length,
								"#EA2EC1",
							)
						}
						ghraphToShow={ghraphToShow}
					/>
					<StatisticsCard
						icon={users}
						// count={totalRegistered?.GetAllUsersCount?.active}
						count={loginUserGraphValue}
						status={"Active users"}
						// trendingIcon={trending_up2}
						// trendingPer={"20"}
						// duration={"Last month"}
						// perColor={"yellow"}
						// iconBgColor={"#26353f"}
						onClick={() =>
							setGraph(
								"Active users",
								100,
								// totalRegistered?.GetAllUsersCount?.active,
								registerdUsers.filter((item) => item.is_login)
									.length,
								"#3386C1",
							)
						}
						ghraphToShow={ghraphToShow}
					/>
				</div>
				<div className="row data">
					<div className="col-lg-9">
						<div className="graph">
							<Line options={options} data={dataArr} />
						</div>
						<div className="row visits-data">
							<div
								className="col-lg-4 cursor-pointer"
								onClick={() => {
									setGraphDataType("Week");
								}}
							>
								<div
									className="radius2 d-flex center my-3 pb-4 pt-5"
									style={{
										flexDirection: "column",
										backgroundColor: `${
											graphDataType == "Week"
												? "#2a2a57"
												: "#8BA937"
										}`,
									}}
								>
									<h5 className="white">Last week</h5>
									<h5 className="light-grey">
										{/* {totalRegistered?.GetAllUsersCount?.lastWeekVisits} */}
									</h5>
									<div className="d-flex center">
										{/* <img src={trending_up} style={{ width: 25 }} /> */}
										{/* <h5 className="green ms-2 mb-0">2.54%</h5> */}
									</div>
								</div>
							</div>
							<div
								className="col-lg-4 cursor-pointer"
								onClick={() => {
									setGraphDataType("Month");
								}}
							>
								<div
									className="radius2 d-flex center my-3 pb-4 pt-5"
									style={{
										flexDirection: "column",
										backgroundColor: `${
											graphDataType == "Month"
												? "#2a2a57"
												: "#246390"
										}`,
									}}
								>
									<h5 className="white">Last Month</h5>
									<h5 className="light-grey">
										{/* {totalRegistered?.GetAllUsersCount?.lastMonthVisits} */}
									</h5>
									<div className="d-flex center">
										{/* <img src={trending_up} style={{ width: 25 }} /> */}
										{/* <h5 className="green ms-2 mb-0">2.54%</h5> */}
									</div>
								</div>
							</div>
							<div
								className="col-lg-4 cursor-pointer"
								onClick={() => {
									setGraphDataType("Year");
								}}
							>
								<div
									className="radius2 d-flex center my-3 pb-4 pt-5"
									style={{
										flexDirection: "column",
										backgroundColor: `${
											graphDataType == "Year"
												? "#2a2a57"
												: "#B52269"
										}`,
									}}
								>
									<h5 className="white">Last Year</h5>
									<h5 className="light-grey">
										{/* {totalRegistered?.GetAllUsersCount?.lastYearVisits} */}
									</h5>
									<div className="d-flex center">
										{/* <img src={trending_up} style={{ width: 25 }} /> */}
										{/* <h5 className="green ms-2 mb-0">2.54%</h5> */}
									</div>
								</div>
							</div>
						</div>
						<div className="bg-dark-blue3 radius1 py-3 px-4 my-1 notification-div">
							<div className="d-flex center justify-content-between">
								<h5 className="white">Notifications</h5>
								<div
									onClick={() =>
										navigate("/user-information")
									}
								>
									<img src={refresh} className="cursor" />
									<span className="white ms-3 cursor">
										See All
									</span>
								</div>
							</div>
							<div className="row">
								{allNotifications?.allNotifications?.map(
									(e, i) => {
										return (
											<div
												key={i}
												className="col-lg-4 my-2"
											>
												<div className="grey-border-bottom py-2">
													<p className="white m-0">
														{e.user_name}
													</p>
													<span className="light-grey">
														Registered on BITS
													</span>
												</div>
											</div>
										);
									},
								)}
							</div>
						</div>
						<div className="bg-dark-blue3 radius1 py-3 px-4 my-4 notification-div">
							<div className="d-flex center justify-content-between">
								<h5 className="white">
									Contract Notifications
								</h5>
								<div onClick={() => navigate("/payment")}>
									<img src={refresh} className="cursor" />
									<span className="white ms-3 cursor">
										See All
									</span>
								</div>
							</div>
							<div className="row">
								{contractEvents?.map((e, i) => {
									return (
										<div key={i} className="col-lg-4 my-2">
											<div className="grey-border-bottom py-2">
												<p className="white m-0">
													{e.address}
												</p>
												<span className="light-grey">
													{e.message} on BITS
												</span>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className="col-lg-3">
						<div
							className="radius2 d-flex center py-4 my-3 cursor-pointer"
							style={{
								flexDirection: "column",
								height: "8.5rem",
								justifyContent: "center",
								backgroundColor: `${
									ghraphToShow.type == "NFTs Sold"
										? "rgb(61, 18, 26)"
										: "#222235"
								}`,
							}}
							onClick={() =>
								setGraph("NFTs Sold", 100, 200, "#8B37A9")
							}
						>
							<h5 className="white mb-1">NFTs Sold</h5>
							<h5 className="red m-0">{nftSoldGraphValue}</h5>
						</div>
						<div
							className="radius2 d-flex center py-4 my-4  cursor-pointer"
							style={{
								flexDirection: "column",
								height: "8.5rem",
								justifyContent: "center",
								backgroundColor: `${
									ghraphToShow.type == "New Registrations"
										? "rgb(61, 18, 26)"
										: "#222235"
								}`,
							}}
							onClick={() =>
								setGraph(
									"New Registrations",
									100,
									(
										newRegistration?.newRegistration
											?.count / 30
									).toFixed(4) || 0,
									"#8B37A9",
								)
							}
						>
							<h5 className="white mb-1">New Registrations</h5>
							<h5 className="red m-0">
								{newRegisterations.length}
							</h5>
						</div>
						<div
							className="radius2 d-flex center py-4 my-4  cursor-pointer"
							style={{
								flexDirection: "column",
								height: "8.5rem",
								justifyContent: "center",
								backgroundColor: `${
									ghraphToShow.type ==
									"Daily Avg Registrations"
										? "rgb(61, 18, 26)"
										: "#222235"
								}`,
							}}
							onClick={() =>
								setGraph(
									"Daily Avg Registrations",
									100,
									(
										newRegistration?.newRegistration
											?.count / 7
									).toFixed(4) || 0,
									"#8B37A9",
								)
							}
						>
							<h5 className="white mb-1">
								Daily Avg Registrations
							</h5>
							<h5 className="red m-0">
								{(
									newRegistration?.newRegistration?.count / 7
								).toFixed(4) || 0}
							</h5>
						</div>

						<div
							className="radius2 d-flex center my-3 pb-4 pt-5 cursor-pointer"
							onClick={() => setGraphDataType("Max")}
							style={{
								flexDirection: "column",
								backgroundColor: `${
									graphDataType == "Max"
										? "rgb(61, 18, 26)"
										: "#8B37A9"
								}`,
							}}
						>
							<h5 className="white">Max</h5>
							{/* <h5 className="light-grey">432</h5> */}
							<div className="d-flex center">
								{/* <img src={trending_up} style={{ width: 25 }} /> */}
								{/* <h5 className="green ms-2 mb-0">2.54%</h5> */}
							</div>
						</div>

						<div className="bg-dark-blue3 radius2 p-3 my-3">
							<div
								id="carouselExampleCaptions"
								className="carousel slide user-view-slider"
								data-bs-ride="carousel"
							>
								<div className="carousel-inner">
									<div className="carousel-item active">
										<div className="d-flex justify-content-between mt-5 mb-2">
											<div>
												<h5 className="white m-0">
													Top Users
												</h5>
												<span className="light-grey">
													By Bought
												</span>
											</div>
											<span className="light-grey">
												Amount
											</span>
										</div>
										{topUsersData?.topByBought?.map(
											(item) => {
												return contactData?.GetAllUsers?.map(
													(e, i) => {
														if (
															item.buyer ==
															e.user_address
														) {
															return (
																<div
																	key={i}
																	className="d-flex justify-content-between grey-border-bottom py-2"
																>
																	<Link
																		to={`user-information/user-profile/${e.id}`}
																	>
																		<h5 className="white m-0">
																			{
																				e.user_name
																			}
																		</h5>
																		{/* <span className="light-grey">{e.status}</span> */}
																	</Link>
																	<span className="pink semi-bold">
																		$
																		{item.price.toFixed(
																			4,
																		)}
																	</span>
																</div>
															);
														}
													},
												);
											},
										)}
									</div>
									<div className="carousel-item">
										<div className="d-flex justify-content-between mt-5 mb-2">
											<div>
												<h5 className="white m-0">
													Top Users
												</h5>
												<span className="light-grey">
													By Sold
												</span>
											</div>
											<span className="light-grey">
												Amount
											</span>
										</div>
										{topUsersData?.topBySold?.map(
											(item) => {
												return contactData?.GetAllUsers?.map(
													(e, i) => {
														if (
															item.seller ==
															e.user_address
														) {
															return (
																<div
																	key={i}
																	className="d-flex justify-content-between grey-border-bottom py-2"
																>
																	<div>
																		<h5 className="white m-0">
																			{
																				e.user_name
																			}
																		</h5>
																		{/* <span className="light-grey">{e.status}</span> */}
																	</div>
																	<span className="pink semi-bold">
																		$
																		{item.price.toFixed(
																			4,
																		)}
																	</span>
																</div>
															);
														}
													},
												);
											},
										)}
									</div>
								</div>

								<button
									className="carousel-control-prev"
									type="button"
									data-bs-target="#carouselExampleCaptions"
									data-bs-slide="prev"
								>
									<span
										className="carousel-control-prev-icon"
										aria-hidden="true"
									></span>
									<span className="visually-hidden">
										Previous
									</span>
								</button>
								<button
									className="carousel-control-next"
									type="button"
									data-bs-target="#carouselExampleCaptions"
									data-bs-slide="next"
								>
									<span
										className="carousel-control-next-icon"
										aria-hidden="true"
									></span>
									<span className="visually-hidden">
										Next
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="grey-border-top last-div">
					<div className="mt-3">
						<span className="light-grey">
							2023 © BITS - BITS.com
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
