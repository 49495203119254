import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./elements.css";
import PublicLayout from "../views/public";
import PrivateLayout from "../views/private";

const Layout = () => {
	return (
		<BrowserRouter>
			<PublicLayout />
			<PrivateLayout />
		</BrowserRouter>
	);
};

export default Layout;
